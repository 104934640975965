import React from 'react';

import {
  Footer,
  useTemplate,
  TimeoutModal,
  AccessibilityButton,
  AccessibilityWrapper,
} from '@fingermarkglobal/cringer.components';

import Logo from '../../assets/images/HardeesLogo.png';
import CartIconImage from '../../assets/images/cart-icon.svg';

import { Header } from '../../components/stateless/header';

const LogoImage = () => <img alt="logo" src={Logo} className="absolute top-0 w-auto h-64 mt-32" />;

const CustomDefaultTemplate = ({
  children,
  footerEnabled = true,
  headerEnabled = true,
  timeoutEnabled = true,
  templateOverride = false,
  showCategoriesHeader = false,
  showAccessibilityButton = true,
}) => {
  const {
    header,
    footer,
    isOnline,
    isCouponEnabled,
    isTimeoutEnabled,
    accessibilityEnabled,
    timeoutActionOverride,
    modalAccessibilityClass,
  } = useTemplate({
    timeoutEnabled,
    templateOverride,
    footerEnabled,
  });

  const showFooterAccessibilityButton = showAccessibilityButton && footerEnabled;

  return (
    <div className="default-template">
      <AccessibilityWrapper
        className="bg-default"
        LogoImage={LogoImage}
        isAccessibilityButtonEnabled={showAccessibilityButton}
      >
        <div className="default-template__content">
          {headerEnabled && (
            <Header
              {...{ ...header, showCategoriesHeader }}
              isLogoEnabled={true}
              isCouponEnabled={isCouponEnabled}
            />
          )}

          <div className="default-template__content__children">{children}</div>

          {footerEnabled && (
            <Footer
              {...footer}
              accessibilityEnabled={accessibilityEnabled}
              showAccessibilityButton={showFooterAccessibilityButton}
              OverwriteCartIcon={CartIconImage}
            />
          )}

          {showAccessibilityButton && (
            <div className="default-template__content__accessibility">
              <AccessibilityButton className="default-template__content__accessibility_button" />
            </div>
          )}

          {isTimeoutEnabled && isOnline && (
            <TimeoutModal
              onTimeout={timeoutActionOverride}
              modalAccessibilityClass={modalAccessibilityClass}
            />
          )}
        </div>
      </AccessibilityWrapper>
    </div>
  );
};

export { CustomDefaultTemplate };
